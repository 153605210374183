import React, { useEffect } from "react";
import Footer from "../layout/Footer";
import Header from "../layout/Header";
import { Form, InputGroup, Container, Row, Col, Button } from "react-bootstrap";
import { useState } from "react";
import { ethers } from "ethers";
import { BUSD, ORIO } from "../common/contracts";
import { useWeb3React } from "@web3-react/core";
import { getShortAddressStr } from "../utils";
import WalletConnect from "../component/WalletConnect";
const Admin = () => {
  const { account, active, library } = useWeb3React();

  const [adminData, setAdminData] = useState({
    _taxPercentage: 0,
    _poolPercentage: 0,
    _BUSDPoolBalance: 0,
    _adminAddress: "",
    _treasuryAddress: "",
    _treasuryPercentage: 0,
    _totalTreasuryAmounting: 0,
    _maxAmountSwapPercentage: process.env.REACT_APP_TOLERANCE || 0,
  });

  const [taxPercentage, setTaxPercentage] = useState(0);
  const [poolPercentage, setPoolPercentage] = useState(0);
  const [BUSDPoolBalance, setBUSDPoolBalance] = useState(0);
  const [adminAddress, setAdminAddress] = useState("");
  const [treasuryAddress, setTreasuryAddress] = useState("");
  const [treasuryPercentage, setTreasuryPercentage] = useState(0);
  const [totalTreasuryAmounting, setTotalTreasuryAmounting] = useState(0);
  const [maxAmountSwapPercentage, setMaxAmountSwapPercentage] = useState(0);

  const [orioStaticContract, setOrioStaticContract] = useState(null);
  const [orioContract, setOrioContract] = useState(null);

  const [txStatus, setTxStatus] = useState("");

  const setData = async (key) => {
    if (orioContract) {
      setTxStatus("Intract on your wallet, Please process Transaction there.");

      try {
        switch (key) {
          case "taxPercentage":
            await orioContract.changeTax(Number(taxPercentage) * 100);

            setTxStatus("Processing Transaction, Waiting For Confirmation.");

            break;

          case "poolPercentage":
            await orioContract.changeTaxAllocation(
              Number(adminData._treasuryPercentage) * 100,
              Number(poolPercentage) * 100
            );

            setTxStatus("Processing Transaction, Waiting For Confirmation.");

            break;

          case "BUSDPoolBalance":
            break;

          case "adminAddress":
            break;

          case "treasuryAddress":
            await orioContract.changeTreasury(treasuryAddress);

            setTxStatus("Processing Transaction, Waiting For Confirmation.");

            break;

          case "treasuryPercentage":
            await orioContract.changeTaxAllocation(
              Number(treasuryPercentage) * 100,
              Number(adminData._poolPercentage) * 100
            );

            setTxStatus("Processing Transaction, Waiting For Confirmation.");

            break;

          case "totalTreasuryAmounting":
            break;

          case "maxAmountSwapPercentage":
            break;

          default:
            break;
        }

        fetchData();
      } catch (error) {
        setTxStatus(
          error?.error?.data?.message ||
            error?.error?.message ||
            "Transaction Reverted"
        );
      }
    }
  };

  const getContract = (address, ABI) => {
    const contract = new ethers.Contract(address, ABI, library);

    return contract.connect(library?.getSigner());
  };

  const getStaticContract = (address, ABI) => {
    const network =
      Number(process.env.REACT_APP_CHAIN_ID) === 5 ? "goerli" : "mainnet";
    const library = new ethers.providers.InfuraProvider(
      network,
      process.env.REACT_APP_INFURA_KEY
    );

    return new ethers.Contract(address, ABI, library);
  };

  const fetchData = async () => {
    if (orioStaticContract) {
      const _taxPercentage = (await orioStaticContract.taxPercent()).toNumber();
      const _poolPercentage =
        (await orioStaticContract.poolPercent()).toNumber() / 100;
      const _BUSDPoolBalance = Number(
        ethers.utils.formatUnits(
          (await orioStaticContract.BUSDPoolBalance()).toString(),
          BUSD.DECIMALS
        )
      ).toFixed(4);

      const _adminAddress = await orioStaticContract.owner();
      const _treasuryAddress = await orioStaticContract.treasuryAddress();
      const _treasuryPercentage =
        (await orioStaticContract.treasuryPercent()).toNumber() / 100;
      const _totalTreasuryAmounting = (
        await orioStaticContract.treasuryPercent()
      ).toNumber();
      // const _maxAmountSwapPercentage = (
      //   await orioStaticContract.maxAmountSwapPercent()
      // ).toNumber();

      setAdminData({
        _taxPercentage,
        _poolPercentage,
        _BUSDPoolBalance,
        _adminAddress,
        _treasuryAddress,
        _treasuryPercentage,
        _totalTreasuryAmounting,
        _maxAmountSwapPercentage: process.env.REACT_APP_TOLERANCE || 0,
      });
    }
  };

  useEffect(() => {
    fetchData();
  }, [orioStaticContract]);

  useEffect(() => {
    if (library) {
      setOrioContract(getContract(ORIO.ADDRESS, ORIO.ABI));
    }
  }, [account, library, active]);

  useEffect(() => {
    setOrioStaticContract(getStaticContract(ORIO.ADDRESS, ORIO.ABI));
  }, []);

  return (
    <>
      <Header />
      <section className="swapsection hwallete-sect pt-5">
        <Container>
          <Row className="d-flex justify-content-center align-items-center">
            <Col xs={12} md={9} className="text-center">
              <h1 className="text-pink mb-4 display-6 fw-500 text-capitalize">
                Note Dapp Under Development
              </h1>
              <h2 className="text-white mb-4 display-6 fw-600 text-capitalize">
                <span className="text-purple me-2">Buy Orio</span>
                with Fiat, Credit Debits Cards or Directly from Bank Transfer
              </h2>
            </Col>
          </Row>
          <Row className="d-flex justify-content-center align-items-start py-5">
            <Col md={6}>
              <Row className="me-0 me-md-4 adminreadform d-flex justify-content-center align-items-center pt-4 pb-3">
                <Col>
                  <div className="d-flex justify-content-end mb-3">
                    <WalletConnect
                      variant="primary"
                      className="wall-conect-btn"
                    />
                  </div>
                  <div className="d-flex justify-content-center h6 my-3">
                    <Form.Label className="text-white">{txStatus}</Form.Label>
                  </div>
                  <div className="d-flex justify-content-between">
                    <Form.Label className="text-white">
                      Tax Percentage
                    </Form.Label>
                    <Form.Label className="text-white">
                      : {adminData._taxPercentage}%
                    </Form.Label>
                  </div>
                  <InputGroup className="mb-4" size="sm">
                    <Form.Control
                      placeholder=""
                      aria-label=""
                      aria-describedby="basic-addon2"
                      variant="primary"
                      value={taxPercentage}
                      onChange={(e) => {
                        setTaxPercentage(Number(e.target.value) || 0);
                      }}
                    />
                    <Button
                      variant="primary"
                      className="main-btn px-5"
                      disabled={!orioContract}
                      onClick={() => setData("taxPercentage")}
                    >
                      Change
                    </Button>
                  </InputGroup>
                  <div className="d-flex justify-content-between">
                    <Form.Label className="text-white">
                      Pool Percentage
                    </Form.Label>
                    <Form.Label className="text-white">
                      : {adminData._poolPercentage}%
                    </Form.Label>
                  </div>
                  <InputGroup className="mb-4" size="sm">
                    <Form.Control
                      placeholder=""
                      aria-label=""
                      aria-describedby="basic-addon2"
                      variant="primary"
                      value={poolPercentage}
                      onChange={(e) => {
                        setPoolPercentage(Number(e.target.value) || 0);
                      }}
                    />
                    <Button
                      variant="primary"
                      className="main-btn px-5"
                      disabled={!orioContract}
                      onClick={() => setData("poolPercentage")}
                    >
                      Change
                    </Button>
                  </InputGroup>
                  <div className="d-flex justify-content-between">
                    <Form.Label className="text-white">
                      {" "}
                      BUSD Pool Balance
                    </Form.Label>
                    <Form.Label className="text-white">
                      : {adminData._BUSDPoolBalance}
                    </Form.Label>
                  </div>
                  <InputGroup className="mb-4" size="sm">
                    <Form.Control
                      placeholder=""
                      aria-label=""
                      aria-describedby="basic-addon2"
                      variant="primary"
                      value={BUSDPoolBalance}
                      onChange={(e) => {
                        setBUSDPoolBalance(Number(e.target.value) || 0);
                      }}
                    />
                    <Button
                      variant="primary"
                      className="main-btn px-5"
                      disabled={!orioContract}
                      onClick={() => setData("BUSDPoolBalance")}
                    >
                      Change
                    </Button>
                  </InputGroup>

                  <div className="d-flex justify-content-between">
                    <Form.Label className="text-white">
                      {" "}
                      Admin Address
                    </Form.Label>
                    <Form.Label className="text-white">
                      : {getShortAddressStr(adminData._adminAddress)}
                    </Form.Label>
                  </div>
                  <InputGroup className="mb-4" size="sm">
                    <Form.Control
                      placeholder="sdfskiuwhbsbgriugsrsrgs"
                      aria-label="sdfskiuwhbsbgriugsrsrgs"
                      aria-describedby="basic-addon2"
                      variant="primary"
                      value={adminAddress}
                      onChange={(e) => {
                        setAdminAddress(Number(e.target.value) || 0);
                      }}
                    />
                    <Button
                      variant="primary"
                      className="main-btn px-5"
                      disabled={!orioContract}
                      onClick={() => setData("adminAddress")}
                    >
                      Change
                    </Button>
                  </InputGroup>
                  <div className="d-flex justify-content-between">
                    <Form.Label className="text-white">
                      {" "}
                      Treasury Address
                    </Form.Label>
                    <Form.Label className="text-white">
                      : {getShortAddressStr(adminData._treasuryAddress)}
                    </Form.Label>
                  </div>
                  <InputGroup className="mb-4" size="sm">
                    <Form.Control
                      placeholder="sdfskiuwhbsbgriugsrsrgs"
                      aria-label="sdfskiuwhbsbgriugsrsrgs"
                      aria-describedby="basic-addon2"
                      variant="primary"
                      value={treasuryAddress}
                      onChange={(e) => {
                        setTreasuryAddress(Number(e.target.value) || 0);
                      }}
                    />
                    <Button
                      variant="primary"
                      className="main-btn px-5"
                      disabled={!orioContract}
                      onClick={() => setData("treasuryAddress")}
                    >
                      Change
                    </Button>
                  </InputGroup>
                  <div className="d-flex justify-content-between">
                    <Form.Label className="text-white">
                      {" "}
                      Treasury Percentage
                    </Form.Label>
                    <Form.Label className="text-white">
                      : {adminData._treasuryPercentage}%
                    </Form.Label>
                  </div>
                  <InputGroup className="mb-4" size="sm">
                    <Form.Control
                      placeholder=""
                      aria-label=""
                      aria-describedby="basic-addon2"
                      variant="primary"
                      value={treasuryPercentage}
                      onChange={(e) => {
                        setTreasuryPercentage(Number(e.target.value) || 0);
                      }}
                    />
                    <Button
                      variant="primary"
                      className="main-btn px-5"
                      disabled={!orioContract}
                      onClick={() => setData("treasuryPercentage")}
                    >
                      Change
                    </Button>
                  </InputGroup>
                  <div className="d-flex justify-content-between">
                    <Form.Label className="text-white">
                      {" "}
                      Total Treasury amounting
                    </Form.Label>
                    <Form.Label className="text-white">
                      : {adminData._totalTreasuryAmounting}
                    </Form.Label>
                  </div>
                  <InputGroup className="mb-4" size="sm">
                    <Form.Control
                      placeholder=""
                      aria-label=""
                      aria-describedby="basic-addon2"
                      variant="primary"
                      value={totalTreasuryAmounting}
                      onChange={(e) => {
                        setTotalTreasuryAmounting(Number(e.target.value) || 0);
                      }}
                    />
                    <Button
                      variant="primary"
                      className="main-btn px-5"
                      disabled={!orioContract}
                      onClick={() => setData("totalTreasuryAmounting")}
                    >
                      Change
                    </Button>
                  </InputGroup>
                  <div className="d-flex justify-content-between">
                    <Form.Label className="text-white">
                      {" "}
                      Max Amount Swap percentage
                    </Form.Label>
                    <Form.Label className="text-white">
                      : {adminData._maxAmountSwapPercentage}%
                    </Form.Label>
                  </div>
                  {/* <InputGroup className="mb-4" size="sm">
                    <Form.Control
                      placeholder=""
                      aria-label=""
                      aria-describedby="basic-addon2"
                      variant="primary"
                      value={maxAmountSwapPercentage}
                      onChange={(e) => {
                        setMaxAmountSwapPercentage(Number(e.target.value) || 0);
                      }}
                    />
                    <Button
                      variant="primary"
                      className="main-btn px-5"
                      disabled={!orioContract}
                      onClick={() => setData("maxAmountSwapPercentage")}
                    >
                      Change
                    </Button>
                  </InputGroup> */}
                </Col>
              </Row>
            </Col>
            {/* <Col md={6}>
              <Row className="ms-0 ms-md-4 adminreadform d-flex justify-content-center align-items-center pt-4 pb-3">
                <Form.Group as={Col} md={12} className="mb-3">
                  <Form.Label className="text-white">
                    Change Tax Percentage{" "}
                  </Form.Label>
                  <Form.Control placeholder="" />
                </Form.Group>
                <Form.Group as={Col} md={12} className="mb-3">
                  <Form.Label className="text-white">
                    Change Tax for pool and treasury
                  </Form.Label>
                  <Form.Control placeholder="" />
                </Form.Group>
                <Form.Group as={Col} md={12} className="mb-3">
                  <Form.Label className="text-white">
                    Change Treasury address
                  </Form.Label>
                  <Form.Control placeholder="" />
                </Form.Group>
                <Form.Group as={Col} md={12} className="mb-3">
                  <Form.Label className="text-white">
                    Change Admin Address
                  </Form.Label>
                  <Form.Control placeholder="" />
                </Form.Group>
                <Form.Group as={Col} md={12} className="mb-3">
                  <Form.Label className="text-white">Add Stablecoin</Form.Label>
                  <Form.Control placeholder="" />
                </Form.Group>
                <Form.Group as={Col} md={12} className="mb-3">
                  <Form.Label className="text-white">
                    Remove Stable Coins
                  </Form.Label>
                  <Form.Control placeholder="" />
                </Form.Group>
                <Form.Group as={Col} md={12} className="mb-3">
                  <Form.Label className="text-white">
                    Change BUSD Stable coin
                  </Form.Label>
                  <Form.Control placeholder="" />
                </Form.Group>
              </Row>
            </Col> */}
          </Row>
        </Container>
      </section>
      <Footer />
    </>
  );
};

export default Admin;
