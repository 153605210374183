import React, { useRef, useState } from "react";
import { Link } from "react-router-dom";
import { Button, Col, Nav } from "react-bootstrap";
import { RiFileCopyLine } from "react-icons/ri";
import { BsCheckLg } from "react-icons/bs";
const CopyCont = () => {
  const [copySuccess, setCopySuccess] = useState(<RiFileCopyLine />);
  const textAreaRef = useRef(null);

  function copyToClipboard(e) {
    textAreaRef.current.select();
    document.execCommand("copy");
    // This is just personal preference.
    // I prefer to not show the the whole text area selected.
    e.target.focus();
    setCopySuccess(<BsCheckLg />);
  }
  return (
    <>
      <div>
        <div className="text-end"></div>
        <form>
          <textarea
            className="cotexarea"
            ref={textAreaRef}
            value="0x335DE1cB210B6d981EF3c5253ed7A39456Ee8F1D"
          />
          <p className="text-white text-center">
            Add the Orio Contract Address
            <div className="d-block d-md-inline">
              <Nav.Link
                className="p-0 d-inline ps-1 text-primary"
                target="_blank"
                href="https://etherscan.io/token/0x335de1cb210b6d981ef3c5253ed7a39456ee8f1d"
              >
                {" "}
                0x335DE1cB...456Ee8F1D{" "}
              </Nav.Link>{" "}
              {
                /* Logical shortcut for only displaying the 
                button if the copy command exists */
                document.queryCommandSupported("copy") && (
                  <Button
                    variant="outline-primary"
                    className="py-0 px-1 border-0 align-text-bottom me-1"
                    onClick={copyToClipboard}
                  >
                    {/* Copy */}
                    {copySuccess}
                  </Button>
                )
              }
            </div>
            in wallet ( Trustwallet or Metamask )
          </p>
        </form>
      </div>
    </>
  );
};

export default CopyCont;
