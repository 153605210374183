import { Button, ButtonGroup } from "react-bootstrap";
import Chart from "chart.js/auto";
import zoomPlugin from "chartjs-plugin-zoom";
import { forwardRef, useEffect, useImperativeHandle, useState } from "react";

Chart.register(zoomPlugin);

let Chartx;
let fdatax;
let currentchart = "price";
let currenttype = "day";
let formattedTime = "";
let formattedTimeM = "";
let formattedTimeD = "";
let dt = "";
let month = "";
let year = "";
let devicetick = 8;
let graphColors = {
  price: "226, 32, 174",
  supply: "31, 36, 230",
};

function loadChartData() {
  return new Promise((resolve, reject) => {
    fetch(process.env.REACT_APP_PRICE_API_URL, { mode: "cors" })
      .then((response) => response.json())
      .then((fdata) => {
        fdatax = fdata;
        resolve(fdata);
      });
  });
}

setTimeout(() => {
  loadChartData();
}, 2000);

function gettime(thetimex) {
  const date = new Date(Number(thetimex) * 1000);
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  year = date.getFullYear();
  month = months[date.getMonth()];
  dt = date.getDate();
  const hours = date.getHours();
  const minutes = date.getMinutes();
  formattedTime = `${year}-${month}`;
  formattedTimeM = `${year}-${month}-${dt}`;
  formattedTimeD = `${year}-${month}-${dt} ${hours}:${minutes}`;
  return { Default: formattedTime, Month: formattedTimeM, Day: formattedTimeD };
}

function getchartdata(val, valtime) {
  if (
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    )
  ) {
    devicetick = 5;
  }
  const ctx = document.getElementById("myChart").getContext("2d");
  const gradient = ctx.createLinearGradient(0, 0, 0, 352);
  gradient.addColorStop(0, "rgba(" + graphColors[val] + ", 0.3)");
  gradient.addColorStop(1, "rgba(" + graphColors[val] + ", 0.1)");

  let labels = [];
  let dataz = Object.values(fdatax ? fdatax[String(val)] : {});

  if (valtime == "default") {
    Object.keys(fdatax ? fdatax[String(val)] : {}).map((thetime, i) => {
      labels.push(gettime(thetime).Default);
    });
  } else if (valtime == "year") {
    Object.keys(fdatax ? fdatax[String(val)] : {}).map((thetime, i) => {
      labels.push(gettime(thetime).Default);
    });

    const newlb = [];
    const newdataz = [];
    for (let t = 0; t < labels.length; t++) {
      if (labels[t].includes(year) == true) {
        newlb.push(labels[t]);
        newdataz.push(dataz[t]);
      }
    }
    labels = newlb;
    dataz = newdataz;
  } else if (valtime == "month") {
    Object.keys(fdatax ? fdatax[String(val)] : {}).map((thetime, i) => {
      labels.push(gettime(thetime).Month);
    });
    const newlb = [];
    const newdataz = [];
    for (let t = 0; t < labels.length; t++) {
      if (
        labels[t].includes(month) == true &&
        labels[t].includes(year) == true
      ) {
        newlb.push(labels[t]);
        newdataz.push(dataz[t]);
      }
    }
    labels = newlb;
    dataz = newdataz;
  } else if (valtime == "day") {
    Object.keys(fdatax ? fdatax[String(val)] : {}).map((thetime, i) => {
      labels.push(gettime(thetime).Day);
    });

    const newlb = [];
    const newdataz = [];
    for (let t = 0; t < labels.length; t++) {
      if (
        labels[t].includes(dt) == true &&
        labels[t].includes(month) == true &&
        labels[t].includes(year) == true
      ) {
        newlb.push((labels[t] || "").split(" ")[1] || "");
        newdataz.push(dataz[t]);
      }
    }
    labels = newlb;
    dataz = newdataz;
  }

  const data = {
    labels: labels,
    datasets: [
      {
        label: val,
        data: dataz,
        fill: "start",
        backgroundColor: gradient,
        tension: 0.6,
        borderColor: "rgba(" + graphColors[val] + ")",
        borderCapStyle: "butt",
        borderDash: [],
        borderDashOffset: 0.0,
        borderJoinStyle: "miter",
        pointBorderColor: "rgba(" + graphColors[val] + ", 1)",
        pointBackgroundColor: "#fff",
        pointBorderWidth: 1,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: "rgba(" + graphColors[val] + ", 1)",
        pointHoverBorderColor: "rgba(" + graphColors[val] + ", 1)",
        pointHoverBorderWidth: 2,
        pointRadius: 1,
        pointHitRadius: 10,
      },
    ],
  };

  const options = {
    responsive: true,
    hover: {
      mode: "nearest",
      intersect: true,
    },
    elements: {
      point: {
        radius: 0,
      },
    },
    scales: {
      x: {
        ticks: {
          autoSkip: true,
          maxTicksLimit: devicetick,
          maxRotation: 0,
          minRotation: 0,
        },
        grid: {
          display: false,
          drawBorder: false,
          drawOnChartArea: false,
          drawTicks: false,
        },
      },
      y: {
        grid: {
          display: true,
          drawBorder: true,
          drawOnChartArea: true,
          drawTicks: true,
        },
      },
    },
    plugins: {
      zoom: {
        zoom: {
          limits: {
            xy: { min: 0, max: 0 },
          },
          wheel: {
            enabled: true,
          },
          pinch: {
            enabled: true,
          },
          mode: "xy",
        },
      },
    },

    animation: {
      x: {
        duration: 2000,
        from: 0,
      },
      y: {
        duration: 2000,
        from: 500,
      },
    },
  };

  const config = { type: "line", data: data, options: options };
  Chartx = new Chart(ctx, config);
}

setTimeout(() => {
  getchartdata(currentchart, currenttype);
}, 3000);

function pricef() {
  if (Chartx && typeof Chartx.destroy == "function") {
    Chartx.destroy();
  }

  Chartx = "";
  currentchart = "price";
  getchartdata(currentchart, currenttype);
}

function supplyf() {
  if (Chartx && typeof Chartx.destroy == "function") {
    Chartx.destroy();
  }

  Chartx = "";
  currentchart = "supply";
  getchartdata(currentchart, currenttype);
}

function onDay() {
  if (Chartx && typeof Chartx.destroy == "function") {
    Chartx.destroy();
  }

  Chartx = "";
  currenttype = "day";
  getchartdata(currentchart, currenttype);
}

function onMonth() {
  if (Chartx && typeof Chartx.destroy == "function") {
    Chartx.destroy();
  }

  Chartx = "";
  currenttype = "month";
  getchartdata(currentchart, currenttype);
}

function onYear() {
  if (Chartx && typeof Chartx.destroy == "function") {
    Chartx.destroy();
  }

  Chartx = "";
  currenttype = "year";
  getchartdata(currentchart, currenttype);
}

function onReset() {
  if (Chartx && typeof Chartx.destroy == "function") {
    Chartx.destroy();
  }

  Chartx = "";
  getchartdata(currentchart, currenttype);
}

const PQChart = forwardRef((props, ref) => {
  const [currentParams, setCurrentParams] = useState({
    type: "price",
    time: "day",
  });

  useImperativeHandle(ref, () => ({
    async reloadData() {
      await loadChartData();

      if (Chartx && typeof Chartx.destroy == "function") {
        Chartx.destroy();
      }

      getchartdata(currentParams.type, currentParams.time);
    },
  }));

  useEffect(() => {
    const interval = setInterval(() => {
      if (currentParams.type !== "" && currentParams.time !== "") {
        setCurrentParams({
          type: currentchart,
          time: "day",
        });
      }
    }, 100);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <>
      <section className="p-1 p-md-3 mt-4 chartmode rounded">
        {/* <Button className="charbtn me-2" variant="outline-danger" size="sm" ></Button> */}
        {/* <Button className="charbtn" variant="outline-success" size="sm" ></Button> */}

        <div className="row d-flex justify-content-between align-items-center mb-3">
          <div className="col-5 col-md-6 ps-2 ps-md-3 text-start text-md-start">
            <ButtonGroup className="charfilter" aria-label="Basic example">
              <Button
                className={`charbtn char-btn ${
                  currentParams.type === "price" ? "active" : ""
                }`}
                variant="primary"
                onClick={pricef}
              >
                Price
              </Button>
              <Button
                className={`charbtn char-btn ${
                  currentParams.type === "supply" ? "active" : ""
                }`}
                variant="primary"
                onClick={supplyf}
              >
                Supply
              </Button>
              {/*<Button className="charbtn" variant="secondary">Right</Button>*/}
            </ButtonGroup>
          </div>
          <div className="col-7 col-md-6 pe-2 pe-md-3 text-end text-md-end">
            <ButtonGroup className="charfilter" aria-label="Basic example">
              <Button
                className={`charbtn char-btn ${
                  currenttype === "day" ? "active" : ""
                }`}
                variant="primary"
                onClick={onDay}
              >
                1D
              </Button>
              <Button
                className={`charbtn char-btn ${
                  currenttype === "month" ? "active" : ""
                }`}
                variant="primary"
                onClick={onMonth}
              >
                1M
              </Button>
              <Button
                className={`charbtn char-btn ${
                  currenttype === "year" ? "active" : ""
                }`}
                variant="primary"
                onClick={onYear}
              >
                1Y
              </Button>
              <Button
                className={`charbtn char-btn ${
                  currenttype === "default" ? "active" : ""
                }`}
                variant="primary"
                onClick={onReset}
              >
                YTD
              </Button>
            </ButtonGroup>
          </div>
        </div>
        <canvas id="myChart"></canvas>
      </section>
    </>
  );
});

export default PQChart;
