import React from "react";
import { Col, Container, Row, Nav, Image } from "react-bootstrap";
// import { BsFacebook } from "react-icons/bs";
// import { FaInstagram } from "react-icons/fa";
// import { IoLogoYoutube } from "react-icons/io";
import { AiFillTwitterCircle } from "react-icons/ai";
import { AiFillGithub } from "react-icons/ai";
import { AiFillMediumCircle } from "react-icons/ai";
import Logo from "../assets/img/logo-footer.png";
const Footer = () => {
  return (
    <>
      <footer className="pt-5 pb-3 text-white">
        <Container>
          <Row className="d-flex justify-content-center align-items-center">
            <Col sm={12} md={2} lg={3} className="text-center text-md-start">
              <Image src={Logo} className="img-fluid" />
            </Col>
            <Col sm={12} md={6} lg={6}>
              <Nav className="justify-content-center text-capitalize text-white">
                <Nav.Item>
                  <Nav.Link href="/" className="text-white fs-18 fw-400">
                    home
                  </Nav.Link>
                </Nav.Item>
                {/* <Nav.Item>
                  <Nav.Link href="/" className="text-white fs-18 fw-400">about</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link href="/" className="text-white fs-18 fw-400">service</Nav.Link>
                </Nav.Item> */}
                <Nav.Item>
                  <Nav.Link
                    href="whitepaper"
                    className="text-white fs-18 fw-400"
                  >
                    White Paper
                  </Nav.Link>
                </Nav.Item>
                {/* <Nav.Item>
                  <Nav.Link href="/" className="text-white fs-18 fw-400">contact us</Nav.Link>
                </Nav.Item> */}
              </Nav>
            </Col>
            <Col sm={12} md={4} lg={3}>
              <Nav className="justify-content-center">
                <Nav.Item>
                  <Nav.Link href="/" className="text-white px-2">
                    Follow us:
                  </Nav.Link>
                </Nav.Item>
                {/* <Nav.Item>
                  <Nav.Link href="/" className="text-white px-2"><BsFacebook/></Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link href="/" className="text-white px-2"><FaInstagram/></Nav.Link>
                </Nav.Item>*/}
                <Nav.Item>
                  <Nav.Link
                    target="_blank"
                    href="https://github.com/ORIO-Network/ORIO"
                    className="text-white px-2"
                  >
                    <AiFillGithub />
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link
                    target="_blank"
                    href="https://twitter.com/orioinc"
                    className="text-white px-2"
                  >
                    <AiFillTwitterCircle />
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link
                    target="_blank"
                    href="https://medium.com/@orio"
                    className="text-white px-2"
                  >
                    <AiFillMediumCircle />
                  </Nav.Link>
                </Nav.Item>
              </Nav>
            </Col>
            <Col xs={12}>
              <p className="text-center pt-4">
                © 2023 Copyright By{" "}
                <a
                  href="www.orio.io"
                  className="text-decoration-none text-purple"
                >
                  ORIO Inc
                </a>
                . All Rights Reserved.{" "}
              </p>
            </Col>
          </Row>
        </Container>
      </footer>
    </>
  );
};

export default Footer;
