import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { Col, Container, Row, Card } from "react-bootstrap";
import { ethers } from "ethers";

import { ORIO } from "../common/contracts";
import PriceChartt from "./PQChart";

const PriceChart = forwardRef((props, ref) => {
  const chartRef = useRef();

  useImperativeHandle(ref, () => ({
    async reloadData() {
      readContractData();
      if (chartRef && chartRef.current) {
        chartRef.current.reloadData();
      }
    },
  }));

  const [OrioContract, setOrioContract] = useState(null);
  const [contractData, setContractData] = useState({
    price: 0,
    earning: 0,
    totalSupply: 0,
    marketCap: 0,
  });

  const getContract = (address, ABI) => {
    const network =
      Number(process.env.REACT_APP_CHAIN_ID) === 5 ? "goerli" : "mainnet";
    const library = new ethers.providers.InfuraProvider(
      network,
      process.env.REACT_APP_INFURA_KEY
    );

    return new ethers.Contract(address, ABI, library);
  };

  const readContractData = async () => {
    if (OrioContract) {
      const _price = await OrioContract.price();
      const price = ethers.utils.formatUnits(_price, ORIO.DECIMALS);
      const _totalSupply = await OrioContract.totalSupply();
      const totalSupply = ethers.utils.formatUnits(_totalSupply, ORIO.DECIMALS);
      const marketCap = (price * totalSupply).toFixed(4);
      const _earning = await OrioContract.Earning();
      const earning = ethers.utils.formatUnits(_earning, ORIO.DECIMALS);

      setContractData({
        price: Number(price)?.toFixed(4),
        earning: Number(earning)?.toFixed(4),
        totalSupply: Number(totalSupply).toFixed(4),
        marketCap,
      });
    }
  };

  useEffect(() => {
    readContractData();
  }, [OrioContract]);

  useEffect(() => {
    setOrioContract(getContract(ORIO.ADDRESS, ORIO.ABI));
  }, []);

  return (
    <>
      <section className="py-5 bg-black-shade1 text-white">
        <Container>
          <Row className="d-flex justify-content-center align-items-center">
            <Col md={12} lg={6} className="pe-3 pe-md-3 pe-lg-5">
              <h2 className="text-white mb-4 display-6 fw-600 text-capitalize">
                <span className="text-purple ms-2">ORIO </span> PRICE CHART
              </h2>
              <p className="text-white mb-5">
                Track the ORIO price and metrics directly from the ORIO DAPP
              </p>
              <PriceChartt ref={chartRef} />
            </Col>
            <Col md={12} lg={6}>
              <Row className="d-flex justify-content-start align-items-start mt-5">
                <Col md={6} className="">
                  <Card className="countercard text-white text-center mb-0 py-4">
                    <Card.Body className="px-0">
                      <div className="">
                        <p className="text-pink fw-600 fs-20">Price</p>
                        <Card.Title className="mb-3 fw-semibold">
                          {contractData.price}
                        </Card.Title>
                        <Card.Text className="text-purple fs-20 fw-600">
                          USD ($)
                        </Card.Text>
                      </div>
                    </Card.Body>
                  </Card>
                </Col>
                <Col md={6} className="mt-4">
                  <Card className="countercard text-white text-center mb-0 py-4 mt-3 mt-md-5">
                    <Card.Body className="px-0">
                      <div className="">
                        <p className="text-pink fw-600 fs-20">Total Supply</p>
                        <Card.Title className="mb-3 fw-semibold">
                          {contractData.totalSupply}
                        </Card.Title>
                        <Card.Text className="text-purple fs-20 fw-600">
                          ORIO
                        </Card.Text>
                      </div>
                    </Card.Body>
                  </Card>
                </Col>
                <Col md={6} className="">
                  <Card className="countercard text-white text-center mb-0 py-4 mt-5 mt-md-0">
                    <Card.Body className="px-0">
                      <div className="">
                        <p className="text-pink fw-600 fs-20">Earning</p>
                        <Card.Title className="mb-3 fw-semibold">
                          {contractData.earning}
                        </Card.Title>
                        <Card.Text className="text-purple fs-20 fw-600">
                          USD ($)
                        </Card.Text>
                      </div>
                    </Card.Body>
                  </Card>
                </Col>
                <Col md={6} className="mt-4">
                  <Card className="countercard text-white text-center mb-0 py-4 mt-3 mt-md-5">
                    <Card.Body className="px-0">
                      <div className="">
                        <p className="text-pink fw-600 fs-20">Market Cap </p>
                        <Card.Title className="mb-3 fw-semibold">
                          {contractData.marketCap}
                        </Card.Title>
                        <Card.Text className="text-purple fs-20 fw-600">
                          USD ($)
                        </Card.Text>
                      </div>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
});

export default PriceChart;
