import { Fragment } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import SendWyre from "react-use-wyre";

import "./App.scss";
import Home from "./views/Home";
import Whitepaper from "./views/Whitepaper";
import Admin from "./views/Admin";

function App({ ...extras }) {
  const apiKey = process.env.REACT_APP_SEND_WYRE_KEY;
  const secretKey = process.env.REACT_APP_SEND_WYRE_SECRET;
  const partnerId = process.env.REACT_APP_SEND_WYRE_PARTNER;

  return (
    <SendWyre apiUrl="https://api.sendwyre.com" apiKey={apiKey} secretKey={secretKey} partnerId={partnerId}>
      <Fragment {...extras} />
      <>
        <BrowserRouter>
          <Routes>
            <Route index element={<Home />} />
            <Route path="/whitepaper" element={<Whitepaper />} />
            <Route path="/admin" element={<Admin />} />
          </Routes>
        </BrowserRouter>
      </>
    </SendWyre>
  );
}

export default App;
