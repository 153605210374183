import React, { useEffect, useState } from "react";
import { Nav, Navbar, Container, Image } from "react-bootstrap";
import { ethers } from "ethers";
import Logo from "../assets/img/logo.png";
import coinImg from "../assets/img/coinicon2.png";
import { ORIO } from "../common/contracts";

const Header = () => {
  const [orioContract, setOrioContract] = useState(null);
  const [price, setPrice] = useState(0);

  const getContract = (address, ABI) => {
    const network =
      Number(process.env.REACT_APP_CHAIN_ID) === 5 ? "goerli" : "mainnet";
    const library = new ethers.providers.InfuraProvider(
      network,
      process.env.REACT_APP_INFURA_KEY
    );

    return new ethers.Contract(address, ABI, library);
  };

  useEffect(() => {
    (async () => {
      if (orioContract) {
        const price = ethers.utils.formatUnits(
          (await orioContract.price()).toString(),
          ORIO.DECIMALS
        );

        setPrice(Number(price).toFixed(4));
      }
    })();
  }, [orioContract]);

  useEffect(() => {
    setOrioContract(getContract(ORIO.ADDRESS, ORIO.ABI));
  }, []);

  return (
    <header className="">
      <Navbar fixed="top" expand="md" className="py-3">
        <Container>
          <Navbar.Brand href="/" className="m-0">
            <Image src={Logo} className="img-fluid" />
          </Navbar.Brand>
          <div className="d-flex justify-content-end align-items-center d-md-none d-block">
            {/* <Button className="btn btn-main-outline px-3 fs-11 me-2">Fiat</Button> */}
            <Nav.Link
              className="text-white fs-11 fw-semibold px-2 me-1"
              disabled
            >
              <Image src={coinImg} className="img-fluid coinimg me-1" /> ${" "}
              {price}
            </Nav.Link>
            <a href="#SwapSection" className="main-btn px-3 fs-10">
              Launch DApp
            </a>
          </div>
          {/* <Navbar.Toggle aria-controls="basic-navbar-nav" /> */}
          <Navbar.Collapse id="basic-navbar-nav d-md-block d-none">
            <Nav className="ms-auto">
              <Nav.Link
                className="text-white fs-18 fw-semibold px-3 me-2"
                disabled
              >
                <Image src={coinImg} className="img-fluid coinimg me-1" /> $
                {price}
              </Nav.Link>
              {/* <Nav.Link href="#link" className="px-4 me-3">
                Fiat
              </Nav.Link> */}
              <a href="#SwapSection" className="main-btn px-4 fs-18 me-3">
                Launch DApp
              </a>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </header>
  );
};

export default Header;
